/* tslint:disable */
/* eslint-disable */
/**
 * Berichtsmanagement
 * Berichte verwalten
 *
 * The version of the OpenAPI document: 1.0.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DateiErsetzenRequest
 */
export interface DateiErsetzenRequest {
    /**
     * Die hochzuladende Datei (Data-URI).
     * @type {string}
     * @memberof DateiErsetzenRequest
     */
    datei: string;
    /**
     * Begründung für das Ersetzen des Dokuments.
     * @type {string}
     * @memberof DateiErsetzenRequest
     */
    bemerkung: string;
}

/**
 * Check if a given object implements the DateiErsetzenRequest interface.
 */
export function instanceOfDateiErsetzenRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "datei" in value;
    isInstance = isInstance && "bemerkung" in value;

    return isInstance;
}

export function DateiErsetzenRequestFromJSON(json: any): DateiErsetzenRequest {
    return DateiErsetzenRequestFromJSONTyped(json, false);
}

export function DateiErsetzenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateiErsetzenRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datei': json['datei'],
        'bemerkung': json['bemerkung'],
    };
}

export function DateiErsetzenRequestToJSON(value?: DateiErsetzenRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datei': value.datei,
        'bemerkung': value.bemerkung,
    };
}

